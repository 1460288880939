/**
 * @param {String} curType pc | mobile
 * @param {String} redirectUrl
 */
export const browserRedirect = (redirectUrl, curType = 'pc') => {
    let userAgent = window.navigator.userAgent.toLocaleLowerCase()
    let mobileTag = ['android', 'iphone', 'ipad']
    let isMobile = mobileTag.some(tag => userAgent.includes(tag))
    if(isMobile ? curType == 'pc' : curType == 'mobile') {
        window.location.href = redirectUrl
    }
}