import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Meta from 'vue-meta'
import { browserRedirect } from './utils'

browserRedirect('//hope.demogic.com/h5')

Vue.use(Meta)
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
	mounted() {
		document.dispatchEvent(new Event('render-event'));
	}
}).$mount('#app')
