<template>
  <div id="iconWall">
    <ul class="damo-logo-ul" style="width: 1136px;">
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/qtf.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/jtd.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/qtd.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/jte.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/qta.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/jtf.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/nuze.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/jtg.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/sjsb.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/jth.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/nuza.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/jta.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/nuzf.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/jtb.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/nuzd.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/jtc.png" />
          </div>
        </div>
      </li>
    </ul>
    <ul
      class="damo-logo-ul"
      style="width: 1278px; margin-left: -39px; margin-top: -72px;"
    >
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/footb1.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nza.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/footb2.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nzb.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/footb3.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nzc.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/footb4.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nzd.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/footb5.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nze.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/footb6.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nzf.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/footb7.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nzg.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back"><img src="../static/images/logo/b1.png" /></div>
          <div class="img-front">
            <img src="../static/images/logo/nzh.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/qte.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nzi.png" />
          </div>
        </div>
      </li>
    </ul>
    <ul class="damo-logo-ul" style="width: 1136px; margin-top: -72px;">
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/qtb.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nuza.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/qtc.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nuzb.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/qtd.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nuzc.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/nuzm.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nuzd.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/qtf.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nuze.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/jtg.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nuzf.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/jta.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nuzg.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/jth.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nuzh.png" />
          </div>
        </div>
      </li>
    </ul>
    <ul
      class="damo-logo-ul"
      style="width: 1278px; margin-left: -39px; margin-top: -72px;"
    >
      <li>
        <div class="img-3d">
          <div class="img-back"><img src="../static/images/logo/b8.png" /></div>
          <div class="img-front">
            <img src="../static/images/logo/nuzi.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back"><img src="../static/images/logo/nuzi.png" /></div>
          <div class="img-front">
            <img src="../static/images/logo/nuzj.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/b10.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nuzk.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/b11.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/nuzm.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/nza.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/tza.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/nzc.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/tzb.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/nzd.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/tzc.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/nze.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/tzd.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/nzf.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/sjsa.png" />
          </div>
        </div>
      </li>
    </ul>
    <ul class="damo-logo-ul" style="width: 1136px; margin-top: -72px;">
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/jtg.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/sjsb.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/tzc.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/sjsc.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/jtf.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/qta.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/nuzj.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/qtb.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/nzg.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/qtc.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/jte.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/qtd.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/nuzi.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/qte.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3ds">
          <div class="img-back">
            <img src="../static/images/logo/jtd.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/qtf.png" />
          </div>
        </div>
      </li>
    </ul>
    <ul class="damo-logo-ul" style="width: 994px; margin-top: -72px;">
      <li>
        <div class="img-3d">
          <div class="img-back">
            <img src="../static/images/logo/sjsc.png" />
          </div>
          <div class="img-front">
            <img src="../static/images/logo/footb1.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back"><img src="../static/images/logo/b2.png" /></div>
          <div class="img-front">
            <img src="../static/images/logo/footb2.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back"><img src="../static/images/logo/b3.png" /></div>
          <div class="img-front">
            <img src="../static/images/logo/footb3.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back"><img src="../static/images/logo/b4.png" /></div>
          <div class="img-front">
            <img src="../static/images/logo/footb4.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back"><img src="../static/images/logo/b5.png" /></div>
          <div class="img-front">
            <img src="../static/images/logo/footb5.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back"><img src="../static/images/logo/b6.png" /></div>
          <div class="img-front">
            <img src="../static/images/logo/footb6.png" />
          </div>
        </div>
      </li>
      <li>
        <div class="img-3d">
          <div class="img-back"><img src="../static/images/logo/b7.png" /></div>
          <div class="img-front">
            <img src="../static/images/logo/footb7.png" />
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "DamoFooter",
  props: {
    msg: String,
  },
  mounted() {
    window.setInterval(() => {
      setTimeout(() => {
        myFunction();
      }, 0);
    }, 5000);
    var clickTimes = 1;
    //总列数
    var lineCount = 6;
    var img3DList = document.querySelectorAll(".img-3d");
    var imgback = document.querySelectorAll(".img-back");
    var imgfront = document.querySelectorAll(".img-front");
    var len = img3DList.length;

    function myFunction() {
      for (var i = 0; i < len; i++) {
        var colNum = parseInt(i / lineCount);
        var rowNum = i % lineCount;
        var delayTime = (colNum + rowNum) * 100;

        img3DList[i].style.transition = ".5s " + delayTime + "ms linear";
        img3DList[i].style.transform = "rotateY(" + 180 * clickTimes + "deg)";
        // imgback[i].style.transition = ".5s " + delayTime + "ms linear";
        // imgback[i].style.transform = "rotateY(" + 180 * (clickTimes + 1) + "deg)";
        // imgfront[i].style.transition = ".5s " + delayTime + "ms linear";
        // imgfront[i].style.transform = "rotateY(" + 180 * (clickTimes + 1) + "deg)";
      }
      clickTimes++;
    }
  },
  methods: {
    handlePoint(e, i) {
      this.$emit("footclick", {
        type: e,
        num: i,
      });
    },
  },
};
</script>

<style scoped>
.damo-logo-ul {
  width: 1200px;
  margin: 0 auto;
  overflow: hidden;
  list-style: none;
}

.damo-logo-ul li {
  width: 142px;
  height: 142px;
  float: left;
  margin: 0;
  position: relative;
  perspective: 1000px;
}

.img-back,
.img-front {
  position: absolute;
  left: 0;
  top: 0;
  width: 142px;
  height: 142px;
  backface-visibility: hidden;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
}

li img {
  vertical-align: middle;
  /* width: 142px; */
}

.img-3d {
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.img-back {
  transform: rotateY(180deg);
}
</style>
