<template>
  <div class="box">
  	<div class="nav-box flex">
  		<img class="logo" @click="gotohome" src="../static/images/home-logo.png">
	    <img class="qiweiLogo" src="../static/images/qiwei-logo.svg" />
  		<div class="flex-i">
  			<router-link to="/index"><span :class="[color?'onss':'']">首页</span></router-link>
  			<router-link to="/product">产品</router-link>
  			<router-link to="/service">服务</router-link>
  			<router-link to="/solution">解决方案</router-link>
  			<router-link to="/about">关于达摩</router-link>
  		</div>			
  		<div class="login tc" @click="handlenav('/gic-web/#/login?redirect=%2Flogin')">登录GIC</div>
  	</div>
  </div>
</template>

<script>
export default {
  name: 'DamoHeader',
  props: {
    color: Boolean
  },
	methods: {
		gotohome() {
		  this.$router.push('/index').catch((e) => {})
		},
		handlenav(_url) {
		  window.open(location.origin + _url, '_blank')
		},
	}
}
</script>

<style scoped>
.nav-box{
	width: 100%;
	align-items:center;
	height: 60px;
}
.nav-box .logo{
	width: 114px;
	height: 44px;
	margin-right: 15px;
}
.qiweiLogo{
	width: 74px;
    margin-right: 80px;
	margin-top: 10px;
}
.nav-box a {
  color: #8E9FC0;
	padding: 0 24px;
}
.nav-box a:hover{
  color: #023E99;
  cursor:pointer
}
.nav-box a.router-link-exact-active {
  color: #023E99;
  font-weight: bold;
}
.onss{
	color: #023E99;
  font-weight: bold;
}
.nav-box .login{
	width: 100px;
	height: 32px;
	border: 1px solid #023E99;
	line-height: 32px;
	color: #023E99;
	background: #FFFFFF;
	transition: all 0.4s;
}
.nav-box .login:hover{
  background: #023E99;
  color: #FFFFFF;
  cursor: pointer;
}
</style>
