<template>
  <div class="footer-box">
		<div @click="handleServer" class="online-service">
			<img class="img-service" src="../static/images/kf_01.png">
			<img class="img-services" src="../static/images/kf_0.png">
			<div class="text-service">在线客服</div>
		</div>
  	<div class="box flex">
  		<div class="footer-a">
				<img class="w-logo" src="../static/images/logo-white.png" alt="">
  			<p class="cc flex f-cont"><img class="mr5" src="../static/images/contact.png">400-888-9570</p>
  			<p class="cc flex f-cont"><img class="mr5" src="../static/images/email.png">admin@demogic.com</p>
  			<p class="cc flex f-cont"><img class="mr5" src="../static/images/addr.png">杭州市余杭区欧美金融城EFC英国中心T2-28楼</p>
  			<p class="s-line"></p>
  			<div class="f12 cc">
				Copyright {{time}} Demogic.com All Rights <br>
				Reserved <a class="cc" style="text-decoration:underline" href="https://beian.miit.gov.cn/" target="_blank">{{ ICP }}</a>
			</div>
  		</div>
  		<div class="footer-b">
  			<div class="tle f18 cf">产品与服务</div>
  			<div class="flex onhover">
  				<div class="con-l">
  					<p class="cc" @click="gotos('/product', 0)">GIC系统</p>
  					<p class="cc" @click="gotos('/product', 1)">好办</p>
  					<p class="cc" @click="gotos('/product', 2)">观云台</p>
  					<p class="cc" @click="gotos('/product', 3)">公众号小程序</p>
  					<p class="cc" @click="gotos('/product', 4)">DataHook</p>
  				</div>
  				<div>
  					<p class="cc" @click="gotos('/service', 0)">实施流程</p>
  					<p class="cc" @click="gotos('/service', 1)">运营服务</p>
  				</div>
  			</div>
  		</div>
  		<div class="footer-c onhover">
  			<div class="tle f18 cf">解决方案</div>
  			<p class="cc" @click="gotos('/solution', 0)">获客</p>
  			<p class="cc" @click="gotos('/solution', 0)">服务</p>
  			<p class="cc" @click="gotos('/solution', 0)">管理</p>
  			<p class="cc" @click="gotos('/solution', 0)">转化</p>
  		</div>
  		<div class="footer-c onhover">
  			<div class="tle f18 cf">关于我们</div>
  			<p class="cc" @click="gotos('/about', 0)">了解达摩</p>
  			<p class="cc" @click="gotos('/about', 1)">企业文化</p>
  			<p class="cc" @click="gotos('/about', 2)">达摩足迹</p>
  		</div>
  		<div class="footer-c">
  			<div class="tle f18 cf">关注达摩</div>
				<div class="flex">
					<div class="mr20">
						<img src="../static/images/code.png">
						<p class="cc f12 tc">-官方微信公众号-</p>
					</div>
					<!-- <div>
						<img src="../static/images/codelogo.png">
						<p class="cc f12">好办APP下载</p>
					</div> -->
				</div>  			
  		</div>
  	</div>
  </div>
</template>

<script>
export default {
	name: 'DamoFooter',
	props: {
		msg: String
	},
	data() {
		const ICP = (() => {
			return window.location.href.includes('www.demogic.com')
				? '浙ICP备15033117号-4'
				: '浙ICP备15033117号-2'
		})();
		return {
			pathurl: '',
			time: '',
			ICP,
		}
	},
	created() {
		this.pathurl = this.$route.path
		this.time = new Date().getFullYear()
	},
	methods: {
		handleServer() {
			window.open('https://demogic.udesk.cn/im_client/?web_plugin_id=66934', '_blank');
		},
		gotos(page, selectId) {
			if(this.pathurl === page) {
				this.$emit('footclick', selectId)
			} else {
				var path = page;
				var selectId = selectId;
				localStorage.setItem("toId",selectId);
				this.$router.push(page).catch((e) => {});
			}
		}
	}
}
</script>

<style scoped>
.online-service{
	position: fixed;
	right: 0;
	bottom: 320px;
	box-shadow: 0 0 10px rgba(0,0,0,.1);
	border-radius: 5px 0 0 5px;
	padding: 8px;
	transition: all .2s;
	background-color: #FFFFFF;
}
.img-service{
	width: 17px;
	height: 17px;
	display: block;
	margin-bottom: 4px;
}
.img-services{
	width: 17px;
	height: 17px;
	display: none;
	margin-bottom: 4px;
}
.text-service{
	font-size: 12px;
	line-height: 16px;
	width: 11px;
	margin: 2px auto;
	color: #a4b7c8;
}
.online-service:hover{
	padding-right: 14px;
	background-color: #023E99;
	cursor:pointer
}
.online-service:hover .img-services{
	display: block;
}
.online-service:hover .img-service{
	display: none;
}
.online-service:hover .text-service{
	color: #FFFFFF
}
.footer-box{
	width: 100%;
	height: 310px;
	background-image: url(../static/images/footer-bg.png);
	background-position: center;
	background-size: cover;
	background-color: #1C2E4A;
}
.onhover .cc:hover{
  color: #FFFFFF;
  cursor:pointer
}
.w-logo{
	width: 165px;
	height: 51px;
	margin: 33px 0 23px 0;
}
.footer-a{
	width: 310px;
}
.footer-a .s-line {
	width: 210px;
	height: 1px;
	background-color: #24395A;
	margin: 15px 0;
}
.footer-b{
	width: 240px;
}
.footer-c{
	width: 210px;
}
.footer-b .tle, .footer-c .tle{
	margin: 50px 0 30px 0;
}
.footer-b .con-l{
	width: 100px;
}
.footer-b p, .footer-c p{
	margin-bottom: 12px;
}
.f-cont{
	line-height: 20px;
	margin-bottom: 5px;
	width: 270px;
}
.f-cont img{
	height: 20px;
	width: 20px;
}
</style>
