<template>
	<div class="number-grow-warp">
		<span ref="numberGrow" :data-time="time" class="number-grow" :data-value="value">0</span>+
  </div>
</template>

<script>
export default {
  props: {
    time: {
      type: Number,
      default: 2
    },
    value: {
      type: Number,
      default: 720000
    },
    numberopen: {
      type: Boolean,
      default: false
    }
  },
	watch: {
		numberopen() {
			if(this.numberopen) {
				this.numberGrow(this.$refs.numberGrow)
			}
		}
	},
  methods: {
    numberGrow (ele) {
      let _this = this
      let step = (_this.value * 10) / (_this.time * 1000)
      let current = 0
      let start = 0
      let t = setInterval(function () {
        start += step
        if (start > _this.value) {
          clearInterval(t)
          start = _this.value
          t = null
        }
        if (current === start) {
          return
        }
        current = start
        ele.innerHTML = current.toString().replace(/(\d)(?=(?:\d{3}[+]?)+$)/g, '$1,')
      }, 10)
    }
  },
  mounted () {
   
  }
}
</script>

<style>
.number-grow-warp{
  transform: translateZ(0);
	display: inline-block;
  margin:0 10px;
  font-size: 28px;
  color: #F65C3C;
}
.number-grow {
  font-family: Arial-BoldMT;
  font-size: 28px;
  color: #F65C3C;
  letter-spacing: 1px;
  line-height:64px;
}
</style>