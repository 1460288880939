<template>
	<div>
		<damo-header :color="true"/>
		<div class="banner-box">
			<div class="box">
				<div class="home-banner">
					<p class="p01 fw">让信息连接一切 成就所有零售者</p>
					<p class="p02">为连锁零售商业提供“互联网+”式系统解决方案</p>
					<p class="p03" @click="gotoabout">了解达摩</p>
				</div>
			</div>
			<div class="banner-con">
				<img src="../static/images/banner_01.gif">
			</div>
		</div>

		<div class="box">
			<div class="tc home-h3">
				<p class="fw">产品</p>
			</div>
			<div :class="[show.product ? 'home-product home-product-on' : 'home-product']">
				<div class="flex">
					<div class="home-product-l">
						<div class="home-product-la p-con">
							<h3>GIC系统</h3>
							<p>基于CRM打造的企业智能管理中枢，是集会员管理、企业管理、营销管理等功能于一体的全新客户管理系统，以智能管理、智能营销武装线下零售业，助力企业打破传统壁垒，实现数字化转型升级。</p>
						</div>
						<div class="home-product-lb p-con">
							<h3>好办工作台</h3>
							<p>基于企业微信为零售企业打造的导购管理工具。通过连接数据与业务终端，赋能导购进行会员资产精准运营及会员任务标准化管理，实现零售链路运营闭环，助力企业降本提效。</p>
						</div>
					</div>
					<div class="home-product-lc">
						<div class="pline-tf">
              <div class="h-animation-a">
                <img class="wh100" src="../static/images/pline_tf.png">
                <div class="h-animation-az"></div>
              </div>
            </div>
						<div class="pline-bl">
              <div class="h-animation-c">
                <img class="wh100" src="../static/images/pline_bl.png">
                <div class="h-animation-cz"></div>
              </div>
            </div>
					</div>
					<div class="home-product-c">
						<div class="pline-tf">
							<div class="h-animation-e">
								<img class="wh100" src="../static/images/pline_b.png">
								<div class="h-animation-ez"></div>
							</div>
						</div>
					</div>
					<div class="home-product-rc">
						<div class="pline-tf">
              <div class="h-animation-b">
                <img class="wh100" src="../static/images/pline_tr.png">
                <div class="h-animation-bz"></div>
              </div>
            </div>
						<div class="pline-bl">
              <div class="h-animation-d">
                <img class="wh100" src="../static/images/pline_br.png">
                <div class="h-animation-dz"></div>
              </div>
            </div>
					</div>
					<div class="home-product-r">
						<div class="home-product-ra p-con">
							<h3>观云台</h3>
							<p>为企业管理层打造的可视化移动数据BI。会员消费行为、销售业绩趋势等自动形成多维度的可视化报表，通过数据沉淀与分析，建立可追踪、可记录、可识别、可优化的零售大数据环境，为企业经营决策提供数据化依据。</p>
						</div>
						<div class="home-product-rd p-con">
							<h3>公众号小程序</h3>
							<p>通过公众号加小程序，打造微信内电商生态闭环，集会员中心、积分商城、消费管理、专属导购等功能于一体，与会员无界触达、无缝连接,为企业建立私域流量的交易中心。</p>
						</div>
					</div>
				</div>
				<div class="home-product-hx">
					<img class="wh100" src="../static/images/pline_cn.svg">
				</div>
				<div class="home-product-pa p-con">
					<h3>DataHook</h3>
					<p>数据管理平台，可以打破数据模板的局限，满足企业个性化的数据需求，解决用户数据分析难点。</p>
				</div>
			</div>
		</div>

		<div class="home-value"></div>

		<div class="box">
			<div class="tc home-h3">
				<p class="fw">解决方案</p>
			</div>
			<div :class="[show.jjfn ? 'home-solution-on': '']">
				<div class="home-solution">
					<div class="dv1">
						<img src="../static/images/solution01_01.png" alt="">
					</div>
					<div class="dv2">
						<img class="home-s-c" src="../static/images/solution01_03.png" alt="">
					</div>
					<div class="dv3">
						<img src="../static/images/solution01_05.png" alt="">
					</div>
					<div class="dv4">
						<img class="fr" src="../static/images/solution01_07.png" alt="">
					</div>
				</div>
				<div class="solution-x">
					<div class="solution-on">
						<img class="img100" src="../static/images/solution_x.png" alt="">
					</div>
				</div>
				<div class="solution02">
					<div class="solutiontsa">
						<img class="img100" src="../static/images/solution02_01.png">
					</div>
					<div class="solutiontsb">
						<img class="img100" src="../static/images/solution02_02.png">
					</div>
				</div>
				<div class="solution03">
					<div class="solution-bg fw">扩大品牌流量池<span>|</span>全面提升客户服务<span>|</span>提升员工工作效率<span>|</span>全域营销提升复购</div>
				</div>
			</div>
		</div>

		<div class="box cooperation">
			<div class="tc home-h3">
				<p class="fw">部分合作品牌</p>
			</div>
			<div class="home-hz">
				<div class="fl">合作品牌<NumberGrow :value="600" :time="2" :numberopen="show.number"></NumberGrow>家</div>
				<div class="fl">上线门店<NumberGrow :value="250000" :time="2" :numberopen="show.number"></NumberGrow>家</div>
				<div class="fl">应用导购<NumberGrow :value="1250000" :time="2" :numberopen="show.number"></NumberGrow>人</div>
				<div class="fl">服务会员<NumberGrow :value="200000000" :time="2" :numberopen="show.number"></NumberGrow>人</div>
			</div>
			<div class="htmleaf-content">
				<damo-logo></damo-logo>
			</div>
		</div>
		<damo-footer @footclick="handleFoot"/>
	</div>
</template>

<script>
import DamoHeader from '@/components/DamoHeader.vue'
import DamoFooter from '@/components/DamoFooter.vue'
import DamoLogo from '@/components/DamoLogo.vue'
import NumberGrow from '@/components/NumberGrow.vue'

export default {
  name: 'home',
  metaInfo: {
    title: '达摩网络-让信息连接一切，成就所有零售者',
		meta: [{                 // set meta
			name: 'description',
			content: '达摩网络是为实体零售业提供一站式数字化升级解决方案的服务商。通过先进的系统工具、全链路管理的运营方法，帮助零售企业提升效果产出。'
		}],
    link: [
      { rel: 'protocol', href: 'https://ding-doc.dingtalk.com/doc#/faquestions/ftpfeu' }
    ]
  },
  components: {
		DamoHeader,
    DamoFooter,
		DamoLogo,
		NumberGrow
  },
  data() {
    return {
			scroll: '',
      show: {
        product: false,
				jjfn: false,
				number: false
      }
    }
  },
	watch: {
	  scroll: function () {
	    this.loadSroll()
	  }
	},
	mounted() {
	  window.addEventListener('scroll', this.handleTabFix, true);
		setTimeout(() => {
			this.show.product = true
		}, 500)
	},
	created() {
		console.log(this.$route.path)
	},
	methods: {
    gotoabout() {
      this.$router.push('/about')
    },
		handleTabFix() {
		  this.scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
		},
		loadSroll() {
		  var self = this
		  if(self.scroll > 700 && this.show.jjfn === false){
		    setTimeout(() => {
		    	this.show.jjfn = true
		    }, 100)
		  }
			if(self.scroll > 1300 && this.show.number === false){
		    setTimeout(() => {
		    	this.show.number = true
		    }, 100)
		  }
		},
		handleFoot(e) {
			console.log(e)
		}
	},
	beforeRouteLeave (to, from, next) {
		window.removeEventListener('scroll', this.handleTabFix, true)
		next()
	}
}
</script>
<style>
  .h-animation-a{
    width: 125px;
    height: 53px;
    position: relative;
    overflow: hidden;
  }
  .h-animation-b{
    width: 122px;
    height: 53px;
    position: relative;
    overflow: hidden;
  }
  .h-animation-c{
    width: 123px;
    height: 52px;
    position: relative;
    overflow: hidden;
  }
  .h-animation-d{
    width: 123px;
    height: 52px;
    position: relative;
    overflow: hidden;
  }
  .h-animation-e{
    width: 8px;
    height: 50px;
    position: relative;
    overflow: hidden;
  }
  .h-animation-az{
    width: 125px;
    height: 53px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    transition: transform .3s ease-in 0s;
    transform: translateX(0)
  }
  .h-animation-bz{
    width: 122px;
    height: 53px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    transition: transform .3s ease-in 0.3s;
    transform: translateX(0)
  }
  .h-animation-cz{
    width: 123px;
    height: 52px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    transition: transform .3s ease-in .6s;
    transform: translateX(0)
  }
  .h-animation-dz{
    width: 123px;
    height: 52px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    transition: transform .3s ease-in 1s;
    transform: translateX(0)
  }
  .h-animation-ez{
    width: 8px;
    height: 50px;
    background-color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    transition: transform .3s ease-in .8s;
    transform: translateY(0)
  }
  .home-product-on .h-animation-az{
    transform: translateX(-125px)
  }
  .home-product-on .h-animation-bz{
    transform: translateX(122px)
  }
  .home-product-on .h-animation-cz{
    transform: translateX(-123px)
  }
  .home-product-on .h-animation-dz{
    transform: translateX(123px)
  }
  .home-product-on .h-animation-ez{
    transform: translateY(50px)
  }
	.home-banner .p01{
		font-size: 36px;
		color: #FFFFFF;
		position: relative;
		z-index: 1;
		padding-top: 120px;
		line-height: 60px;
	}
	.home-banner .p02{
		font-size: 20px;
		line-height: 50px;
		color: #b9c2e2;
		position: relative;
		z-index: 1;
	}
	.home-banner .p03{
		width: 94px;
		height: 34px;
		line-height: 34px;
		background-color: #f65c3c;
		color: #fff;
		text-align: center;
		position: relative;
		z-index: 1;
		margin-top: 20px;
	}
  .home-banner .p03:hover{
    background-color: #e4401d;
    box-shadow: 3px 3px 5px rgba(0,0,0,.2);
    cursor: pointer
  }
	.home-h3{
		font-size: 30px;
		color: #1B2A42;
		margin-top: 45px;
		position: relative;
		padding-bottom: 15px;
	}
	.home-h3::before{
		content: "";
		position: absolute;
		bottom: 0;
		left: 50%;
		width:36px;
		height:3px;
		margin-left: -18px;
		background: linear-gradient(270deg,rgba(49,143,220,1) 0%,rgba(22,86,183,1) 100%);
		display: block;
	}
	.home-product {
		width: 100%;
		height: 640px;
		position: relative;
	}
	.home-product-l{
		width: 365px;
		padding-top: 67px;
	}
	.home-product-r{
		width: 374px;
		padding-top: 50px;
	}
	.home-product-lc{
		width: 125px;
		padding-top: 128px;
		margin-left: 7px;
	}
	.home-product-rc{
		width: 125px;
		padding-top: 133px;
		margin-right: 7px;
	}
	.home-product-rc .pline-tf{
		margin-bottom: 116px;
	}
	.home-product-c{
		width: 204px;
		height: 418px;
		padding-top: 368px;
	}
	.home-product-c .pline-tf{
		width: 8px;
		height: 50px;
		margin: 0 auto;
	}
	.home-product-lc .pline-tf{
		margin-bottom: 116px;
	}
	.home-product-la{
		width: 100%;
		height: 144px;
		margin-bottom: 42px;
		transition: opacity 0.6s ease-in .3s;
		opacity: 0;
	}
	.home-product-ra{
		width: 100%;
		height: 156px;
		margin-bottom: 42px;
		transition: opacity 0.6s ease-in .6s;
		opacity: 0;
	}
	.home-product-rd{
		width: 100%;
		height: 140px;
		transition: opacity 0.6s ease-in 1s;
		opacity: 0;
	}
	.home-product-lb{
		width: 100%;
		height: 140px;
		transition: opacity 0.6s ease-in 1s;
		opacity: 0;
	}
	.home-product-pa{
		position: absolute;
		bottom: 100px;
		left: 50%;
		margin-left: -184px;
		width: 368px;
		height: 110px;
		transition: opacity .6s ease-in 1.2s;
		opacity: 0;
	}
	.home-product-on .p-con{
		opacity: 1;
	}
	.p-con{
		box-shadow: 0px 2px 10px 1px rgba(185,189,205,0.25);
		padding: 5px 20px 0 20px;
		box-sizing: border-box;
	}
	.p-con h3{
		color: #023E99;
		font-size: 20px;
		line-height: 2;
	}
	.p-con p{
		font-size: 14px;
		color: #60708F;
		line-height: 20px;
		word-wrap: break-word
	}
	.home-product-hx{
		position: absolute;
		top: 128px;
		left: 453px;
		width: 280px;
		height: 256px;
	}

	.home-solution{
		width: 980px;
		margin: 60px auto 0;
		height: 68px;
	}
	.home-solution .dv1{
		transition: opacity 0.3s ease-in;
		opacity: 0;
	}
	.home-solution .dv2{
		padding-left: 34px;
		box-sizing: border-box;
		transition: opacity 0.3s ease-in 0.3s;
		opacity: 0;
	}
	.home-solution .dv3{
		padding-left: 60px;
		box-sizing: border-box;
		transition: opacity 0.3s ease-in .5s;
		opacity: 0;
	}
	.home-solution .dv4{
		transition: opacity 0.3s ease-in .7s;
		opacity: 0;
	}
	.home-s-c{
		margin: 0 auto;
	}
	.home-solution div{
		width: 245px;
		float: left;
	}
	.solution02{
		width: 918px;
		margin: 0 auto;
	}
	.solution-x{
		width: 986px;
		height: 117px;
		margin: 0 auto 10px;
	}
	.solution-on{
		width: 986px;
		height: 0;
		transition: height .5s ease-in .6s;
	}
	.solutiontsa{
		width: 918px;
		height: 70px;
		transition: opacity 0.3s ease-in 1s;
		opacity: 0;
	}
	.solutiontsb{
		width: 918px;
		height: 68px;
		transition: opacity 0.3s ease-in 1.2s;
		opacity: 0;
	}
	.home-solution-on .solution-on{
		height: 117px;
	}

	.home-value{
		width: 100%;
		min-width: 1200px;
		height: 120px;
		background-image: url(../static/images/value.png);
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.solution03{
		width: 1200px;
		height: 96px;
		padding-bottom: 80px;
	}
	.solution03 .solution-bg{
		width: 1014px;
		height: 96px;
		background-image: url(../static/images/solution03.png);
		background-size: cover;
		display: block;
		margin: 0 auto;
		color: #fff;
		font-size: 20px;
		line-height: 96px;
		text-align: center;
		transition: opacity 0.3s ease-in 1.4s;
		opacity: 0;
	}
	.home-solution-on .solution03 .solution-bg, .home-solution-on .home-solution .dv1, .home-solution-on .home-solution .dv2, .home-solution-on .home-solution .dv3, .home-solution-on .home-solution .dv4, .home-solution-on .solutiontsa, .home-solution-on .solutiontsb{
		opacity: 1;
	}
	.solution-bg span{
		padding: 0 12px;
    font-weight: normal;
	}
	.cooperation{
		height: 800px;
		background-image: url(../static/images/map.png);
		background-position: center;
		background-size: contain;
		background-repeat: no-repeat;
		padding-top: 1px;
	}
	.cooperation .home-h3{
		margin-top: 22px;
	}
	.home-hz{
		font-size: 16px;
		color: #333;
		padding-left: 60px;
		overflow: hidden;
		margin: 30px 0px;
	}
  .home-hz .fl{
		margin-right: 50px;
	}
</style>
